<template>
  <div>
    <canvas ref="canvas" @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing"></canvas>
    <button @click="clearCanvas">Clear</button>
    <img v-if="signature" :src="signature" alt="Signature">
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawing: false,
      signature: null,
      context: null
    };
  },
  mounted() {
    this.context = this.$refs.canvas.getContext('2d');
  },
  methods: {
    startDrawing() {
      this.drawing = true;
      this.context.beginPath();
    },
    draw(event) {
      if (!this.drawing) return;
      const rect = this.$refs.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.context.lineTo(x, y);
      this.context.stroke();
    },
    stopDrawing() {
      this.drawing = false;
      this.signature = this.$refs.canvas.toDataURL();
    },
    clearCanvas() {
      this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      this.signature = null;
    }
  }
};
</script>
